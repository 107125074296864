import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { BusinessClassification } from 'src/app/models/prove-verification.model';


const HEADER_HIDE_LOADER = 'X-No-Loader';


@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private readonly businessClassifications = new BehaviorSubject<BusinessClassification[]>([]);
  
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.baseUrl = `${this.config.apiUrl}/organization`;
  }

  updateBusinessProfile(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.put(`${this.baseUrl}/profile`, data, { headers });
  }

  inviteMembers(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post(`${this.baseUrl}/user-invites`, data, { headers });
  }

  inviteBeneficialOwners(data) {
    return this.http.post(`${this.baseUrl}/beneficial-owners`, data);
  }

  getMembers() {
    return this.http.get(`${this.baseUrl}/users`);
  }

  getBeneficialOwners() {
    return this.http.get(`${this.baseUrl}/beneficial-owners`);
  }

  getInvitedMembers() {
    return this.http.get(`${this.baseUrl}/user-invites`);
  }

  updateUserInvite(data) {
    return this.http.put(`${this.baseUrl}/user-invites`, data);
  }

  updateUserOrganizationRoles(data) {
    return this.http.put(`${this.baseUrl}/user-role`, data);
  }

  setOrganizationBeneficialOwnersPrompt(payload: {
    hasBeneficialOwners: boolean;
  }) {
    return this.http.post(`${this.baseUrl}/beneficial-owners/prompt`, payload);
  }

  resendInvitation(payload: {
    id: string;
  }) {
    return this.http.post(`${this.baseUrl}/user-invites/resend`, payload);
  }

  deleteInvites(payload: {
    ids: string[];
  }) {
    return this.http.delete(`${this.baseUrl}/user-invites`, {
      params: payload
    });
  }

  confirmBeneficialOwners() {
    return this.http.post(`${this.baseUrl}/beneficial-owners/confirm`, {});
  }


  getBusinessClassifications(): Observable<BusinessClassification[]> {
    // Return cached data if available
    if (this.businessClassifications.value.length > 0) {
      return this.businessClassifications.asObservable();
    }

    // Fetch from API if no cached data
    return this.http.get<{ data: BusinessClassification[]}>(`${this.baseUrl}/classifications`).pipe(
      map(response => response.data),
      tap(classifications => {
        this.businessClassifications.next(classifications);
      })
    );
  }
}
