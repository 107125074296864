import { ILoan } from '../core/services';
import { IOffer, OfferStatuses } from '../marketing-inbox/event-offer/event-offer.model';
import { PaymentIntentModel } from '../models/payment-intent.model';
import { ISellerListing, ISellerListingDisplayTypes } from '../models/seller-listing.model';
import { IUser } from '../models/user.model';
import { IBadgeColor } from '../widgets/badge/badge.component';
import { BaseDocument } from './document.models';

export interface DealOrganization {
  _id: string;
  name: string;
  verified: boolean;
  city: string;
  zip: string;
  zipcode: string;
  logo: string;
}

export enum DealStatus {
  Pending = 'pending',
  Sold = 'sold',
  Cancelled = 'cancelled',
}

export interface DealsUser extends IUser {
  shortName?: string;
}

export enum DealsStatuses {
  Active = 'active',
  Sold = 'sold',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Pending = 'pending',
}
export enum TitleDeliveryMethods {
  InPerson = 'InPerson',
  Transport = 'Transport', //With shipper or in vehicle
  TransferService = 'TransferService',
}

export enum TitleTransferOptions {
  TransferService = 'TransferService',
  Self = 'self',
}

export const DEAL_MESSAGES = {
  STEP_1_COMPLETE: '{{user}} completed Step 1 of the deal, please confirm all information is correct.',
  STEP_3_BUYER_COMPLETE: '{{user}} completed the transfer of funds',
  STEP_3_SELLER_COMPLETE: '{{user}} confirmed the transfer of funds.',
};

export enum IDealTitleTypes {
  Attachment = 'attachment',
}

export interface IDealPerson {
  id: string;
  info: IUser;
  confirmedDate: Date;
  addonServicesConfirmedDate: Date;
  invited: boolean;
  organization?: DealOrganization;
  organizationId?: string;
  titlePurchaseShow: boolean;
}

export interface IDealSeller extends IDealPerson {
  confirmPaymentDate: Date;
}

export interface IDealBuyer extends IDealPerson {
  entity: {
    entityName: string;
    isAuthorizedSigner: boolean;
    signerTitle: string;
  };
}

export interface Person extends IDealPerson {
  role: string;
}

export interface IDealPaymentMethod {
  amount: number;
  transferDate: Date;
}

export interface IDealBase {
  _id: string;
  payment: {
    privateAutoPay: IDealPaymentMethod;
    cash: IDealPaymentMethod;
    loan: IDealPaymentMethod;
    crypto: {
      btc: IDealPaymentMethod;
    };
  };

  loan: ILoan;
  odometer: string;
  offer: IOffer;
  state: string;
  status: DealsStatuses;
  seller: IDealSeller;
  buyer: IDealBuyer;
  listing: ISellerListing;
  allDocumentsSigned: boolean;
  disclosure: string;
  isBuyerFundsReady: boolean;
  isBuyersFundsSufficient?: boolean;
  isDealNowPaidByBuyer: boolean;
  titleDeliveryMethod?: TitleDeliveryMethods;
  titleTransferOption?: TitleTransferOptions;
  createdAt: string;
  updatedAt: string;
  buyersFundsSufficient?: boolean;
  buyerPaymentMethod?: PaymentIntentModel;
  sellerPaymentMethod?: PaymentIntentModel;
  closingFeePayer?: DealClosingFeePayerEnum;
  loanPayOffPaymentMethod?: PaymentIntentModel;
  documents: BaseDocument[];
}

export interface IDeal extends IDealBase {
  isPartiesSectionComplete: boolean;
  isVehicleInfoComplete: boolean;
  isPaymentMethodComplete: boolean;
  isDocumentSigningComplete: boolean;
  isPaymentTransferComplete: boolean;
  isTitleSectionComplete: boolean;
  isAddonServiceBuyerStepComplete: boolean;
  isAddonServicesSellerStepComplete: boolean;
  isAddonServicesStepComplete: boolean;
  isDealLoanFinancedAndNotSold: boolean;
  isPaymentTransferStarted: boolean;
  isPaymentLoanEditable?: boolean;
  isOfferSectionConfirmed?: boolean;
  latestDealDocument?: any;
  outdatedPrice?: number;
  title: {
    type: IDealTitleTypes;
    isReviewed: boolean;
  };
}

export enum DealClosingFeePayerEnum {
  Split = 'split',
  Buyer = 'buyer',
  Seller = 'seller',
}

interface DealPerson {
  img: string;
  name: string;
  isVerified: boolean;
}

export interface DealItem {
  _id: string;
  buyer: DealPerson | null;
  seller: DealPerson | null;
  chatId: string;
  offerPrice: number;
  date: Date;
  externalListing?: boolean;
  linkShared?: boolean;
  badge: {
    text: string;
    color: IBadgeColor;
    isShown: boolean;
  } | null;
  listing: {
    _id: string;
    img: string;
    name: string;
    slug: string;
    price: number;
    miles: number;
    vinNumber?: string;
    vehicleType?: string;
    displayType: ISellerListingDisplayTypes;
    originalSourceInfo: { sourceSite: string; source_vdp: string };
    additionalDetails?: { boat?: { hours?: number } };
  };
  deal: {
    _id: string;
    status: DealsStatuses;
  } | null;
}

export interface DealsOfferItem {
  id: string;
  chatId: string;
  createdAt: string;
  updatedAt?: string;

  offeredBy: DealsUser;
  offeredTo: DealsUser;

  buyer: DealsUser;
  seller: DealsUser;

  status: OfferStatuses;
  cancelledReason: string;
  cancelledReasonClass: string;

  vehicle: {
    id: string;
    name: string;
    mileage: number;
    price: number;
    image: string;
    slug: string;
    vehicleType: string;
  };

  offer: {
    price: number;
    subjects: string;
  };

  deal?: {
    id: string;
    seller: IUser;
    buyer: IUser;
    status: DealsStatuses;
    createdAt: string | Date;
    updatedAt: string | Date;
    isVehicleInfoComplete: boolean;
    isPaymentTransferComplete: boolean;
    isDocumentSigningComplete: boolean;
    isAddonServicesStepComplete?: boolean;
  };
  listing?: ISellerListing;
}

export interface ICreditReport {
  bank: {
    name: string;
    logo: string;
    address: {
      addressLine1: string;
      city: string;
      state: string;
      zip: string;
    };
  };
  estimatedLoanBalance: number;
  monthlyLoanPayment: number;
  lastPaymentDate: Date;
  listingId: string;
  userId: string;
  lastUpdated: Date;
  dealId: string;
  accountNumber: string;
  _id: string;
}
