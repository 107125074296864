import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';
import {
  GoogleAnalyticsEventConfig,
  IPaymentType,
  PaymentElementConfig,
  PaymentIntentConfig,
} from '../payment-element/payment-element.component';
import { ISellerListing } from 'src/app/models';
import { IDeal } from 'src/app/deals/deals.models';

@Component({
  selector: 'app-modal-title-transfer',
  templateUrl: './modal-title-transfer.component.html',
  styleUrl: './modal-title-transfer.component.scss',
})
export class ModalTitleTransferComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();
  @Input() selectedVehicle: ISellerListing;
  @Input() deal: IDeal;

  modalConfig: ModalConfig = {};
  paymentIntentConfig: PaymentIntentConfig;
  googleAnalyticsEvent: GoogleAnalyticsEventConfig;
  showPaymentElement: boolean = false;
  paymentElementConfig: PaymentElementConfig;
  vehicleSelected: ISellerListing;

  ngOnInit(): void {
    this.paymentElementConfig = {
      productTitle: 'Title transfer service',
      buttonText: 'Purchase Mail service',
    };
    this.modalConfig = {
      name: 'Title Transfer',
      hideClose: false,
      headerBg: '#E8F8FF',
      headerText: 'Title transfer service',
      hideLogo: true,
      beforeClose: async () => {
        this.isOpen = false;
        this.showPaymentElement = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
      this.vehicleSelected = this.selectedVehicle;
      this.paymentIntentConfig = {
        type: IPaymentType.TITLE_TRANSFER,
        metadata: { listingId: this.selectedVehicle._id, dealId: this.deal._id },
      };
    }
  }

  getService() {
    this.showPaymentElement = true;
  }

  onPaymentSuccess(): void {
    //TODO: Handle successful payment. Integrate when API work is done.
  }
}
