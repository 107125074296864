// multiple-email-input.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { getValueAccessorProvider } from 'src/app/shared/functions';

@Component({
  selector: 'app-multiple-email-input',
  templateUrl: './multiple-email-input.component.html',
  styleUrls: ['./multiple-email-input.component.scss'],
  providers: [getValueAccessorProvider(MultipleEmailInputComponent)],
})
export class MultipleEmailInputComponent implements ControlValueAccessor {
  @Input() placeholder = 'Enter email address';
  @Input() isInvalid = false;
  @Input() invalidFeedback = 'At least one email is required';
  @Output() emailsChange = new EventEmitter<string[]>();

  emails: string[] = [];
  currentEmail = '';
  disabled = false;
  currentInputInvalid = false;
  currentInputError = '';
  editingIndex: number | null = null;

  // ControlValueAccessor implementation
  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  onEmailInput(event: any) {
    const input = event.target.value;
    const lastChar = input[input.length - 1];
    
    if (lastChar === ',') {
      event.preventDefault();
      this.addEmail();
    }

    // Clear validation errors when user starts typing
    this.currentInputInvalid = false;
    this.currentInputError = '';
  }

  onEmailKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.addEmail();
    }

    // Handle backspace to edit last chip
    if (event.key === 'Backspace' && !this.currentEmail && this.emails.length > 0) {
      event.preventDefault();
      const lastEmail = this.emails[this.emails.length - 1];
      this.emails.pop();
      this.currentEmail = lastEmail;
      this.onChange(this.emails);
      this.emailsChange.emit(this.emails);
    }
  }

  onEmailBlur() {
    this.onTouched();
    const email = this.currentEmail.trim();
    
    if (email) {
      if (this.validateEmail(email)) {
        if (!this.emails.includes(email)) {
          this.addEmail();
        } else {
          this.currentInputInvalid = true;
          this.currentInputError = 'This email has already been added';
          this.currentEmail = '';
        }
      } else {
        this.currentInputInvalid = true;
        this.currentInputError = 'Please enter a valid email address';
      }
    }
  }

  addEmail() {
    const email = this.currentEmail.trim();
    if (email && this.validateEmail(email) && !this.emails.includes(email)) {
      if (this.editingIndex !== null) {
        this.emails[this.editingIndex] = email;
        this.editingIndex = null;
      } else {
        this.emails.push(email);
      }
      this.currentEmail = '';
      this.currentInputInvalid = false;
      this.currentInputError = '';
      this.onChange(this.emails);
      this.emailsChange.emit(this.emails);
    }
  }

  removeEmail(email: string) {
    this.emails = this.emails.filter(e => e !== email);
    this.onChange(this.emails);
    this.emailsChange.emit(this.emails);
  }

  validateEmail(email: string): boolean {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  }

  // ControlValueAccessor methods
  writeValue(emails: string[]): void {
    this.emails = emails || [];
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}