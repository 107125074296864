import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { FinancialAccount, IBalance, Transaction, WalletTransferPayload } from 'src/app/models/financial-account.model';

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({
  providedIn: 'root'
})
export class FinancialAccountService {
  private readonly accountSubject = new BehaviorSubject<FinancialAccount>(null);
  private readonly transactionsSubject = new BehaviorSubject<Transaction[]>([]);
  private readonly balanceSubject = new BehaviorSubject<IBalance>(null);

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {}

  // Observables
  get account$() {
    return this.accountSubject.asObservable().pipe(filter(Boolean));
  }

  get transactions$() {
    return this.transactionsSubject.asObservable();
  }

  get balance$() {
    return this.balanceSubject.asObservable();
  }

  getFinancialAccount(hideLoader = false): Observable<{ data: FinancialAccount }> {
    const headers = new HttpHeaders().set(HEADER_HIDE_LOADER, hideLoader ? '1' : '');
    
    return this.http.get<{ data: FinancialAccount }>(`${this.config.apiUrl}/bank/wallet/account`, { headers }).pipe(
      tap(response => {
        this.setBalance({
          value: response.data.currentBalance,
          currency: response.data.currency
        });
        return this.accountSubject.next(response.data)
      })
    );
  }

  getTransactions(hideLoader = false): Observable<{ data: Transaction[] }> {
    const headers = new HttpHeaders().set(HEADER_HIDE_LOADER, hideLoader ? '1' : '');

    return this.http.get<{ data: Transaction[] }>(`${this.config.apiUrl}/bank/wallet/transactions`, {
      headers,
    }).pipe(
      tap(response => this.transactionsSubject.next(response.data))
    );
  }

  walletTransfer(payload: WalletTransferPayload): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(`${this.config.apiUrl}/bank/wallet/transfer`, payload);
  }

  refreshAccountData(hideLoader = true) {
    return this.getFinancialAccount(hideLoader);
  }

  refreshTransactions(hideLoader = true) {
    return this.getTransactions(hideLoader);
  }

  setBalance(balance: IBalance) {
    this.balanceSubject.next(balance);
  }

  createFinancialAccount() {
    return this.http.post(`${this.config.apiUrl}/bank/wallet/account`, {});
  }
}