import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LightListingValues } from 'src/app/core/services';
import { HistoryReportsService } from 'src/app/core/services/history-reports.service';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@Component({
  selector: 'app-modal-reports-purchase-complete',
  templateUrl: './modal-reports-purchase-complete.component.html',
  styleUrls: ['./modal-reports-purchase-complete.component.scss'],
})
export class ModalReportsPurchaseCompleteComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;

  @Input() listing: LightListingValues;
  @Input() isLive = true;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<any>();
  @Output() viewReport = new EventEmitter<string>();

  modalConfig: ModalConfig = {};

  markasSold = false;
  constructor(private readonly historyReportsService: HistoryReportsService) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Report Purchase Success',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.historyReportsService.setOpenPurchaseReportsModal(false);
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  setViewReport(type) {
    this.viewReport.emit(type);
  }
}
