import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ISellerListing } from 'src/app/models';

interface Payment {
  id: string;
  customer: string;
  amount: number;
  paymentMethod: string;
  createdAt: string;
  completedAt: string;
}

export interface GetReportItem {
  _id: string;
  purchaserId: string;
  payment: Payment;
  documentType: string;
  createdAt: Date;
  updatedAt: Date;
  pendingDealNow?: {
    amount: number;
    paidBy: 'buyer' | 'seller' | null;
  };

  listing: Pick<
    ISellerListing,
    | '_id'
    | 'slug'
    | 'Trim'
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'vinNumber'
    | 'uploadImages'
    | 'originalSource'
  >;
}

interface GetReportsResponse {
  reports: GetReportItem[];
}

@Injectable()
export class HistoryReportsService {
  private baseUrl: string;
  private reportsSubject = new BehaviorSubject<GetReportItem[]>([]);
  private readonly openPurchaseReportsModal = new BehaviorSubject<boolean>(false);

  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.baseUrl = `${this.config.apiUrl}/services/history-reports`;
  }

  get reports(): Observable<GetReportItem[]> {
    return this.reportsSubject.asObservable();
  }

  get getOpenPurchaseReportsModal() {
    return this.openPurchaseReportsModal.asObservable();
  }

  setOpenPurchaseReportsModal(val: boolean) {
    this.openPurchaseReportsModal.next(val);
  }

  updateReports() {
    this.http
      .get<GetReportsResponse>(`${this.baseUrl}`)
      .pipe(map((response) => response.reports))
      .subscribe((reports) => this.reportsSubject.next(reports));
  }

  isReportNotPaid(listingId: string) {
    return this.http.get<{ isNotPaid: boolean }>(`${this.baseUrl}/is-not-yet-paid`, { params: { listingId } });
  }

  removePendingDealNow(reportId: string) {
    return this.http.get<{ success: boolean }>(`${this.baseUrl}/remove-pending-deal-now`, { params: { reportId } });
  }
}
