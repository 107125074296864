import { ShippingProviders } from './shipping-order.model';

export interface QuoteModel {
  distanceInMiles: number;
  fare: number;
  name: string;
  type: string;
  pickup: {
    address: string;
  };
  provider: ShippingProviders;
  deliveryDate?: Date;
  isExpress?: boolean;
  price_ref_id?: string;
  estimatedDelivery?: Date;
}
