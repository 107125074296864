import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { map, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { IDeal } from 'src/app/deals/deals.models';
import { IDealDocumentGenerated, IDocumentTypes, IListingDocumentBasic } from 'src/app/deals/documents.models';
import { Utils } from 'src/app/shared/utils';
import { IUserSignature } from 'src/app/models';
import { GonativeService } from './gonative.service';
import { NotificationService } from './notification.service';

interface IDealDocument {
  _id: string;
  buyerSignedDate: string;
  sellerSignedDate: string;
  deal: string;
  documentTemplate: string;
  name: string;
  url: string;
  key: string;
}

interface SignDocumentRequest {
  dealId: string;
  documentId: string;
  signature: IUserSignature;
}

interface IDealUploadedDocument {
  name: string;
  url: string;
  deal: string;
}

interface CreateDealUploadedDocumentsRequest {
  dealId: string;
  documents: IDealUploadedDocument[];
}

interface ModifyDealUploadedDocumentsRequest {
  dealId: string;
  documentId: string;
  document: Partial<IDealUploadedDocument>;
}

interface DeleteDealUploadedDocumentsRequest {
  dealId: string;
  documentId: string;
}

interface CreateDocumentsListingRequest {
  listingId: string;
  documents: Partial<IListingDocumentBasic>[];
}

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly http: HttpClient,
    private readonly gonativeService: GonativeService,
    private readonly notifService: NotificationService
  ) {}

  getDealDocuments(deal: IDeal, userId: string, hideLoaderInterceptor = false): Observable<IDealDocumentGenerated[]> {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    const dealId = deal._id;

    return this.http.get(`${this.config.apiUrl}/docs/deals/${dealId}`, { headers }).pipe(
      // TODO interface array of Generated and Uploaded
      map(({ documents }: any) => {
        return documents.map((doc) => {
          const isUserSeller = userId === deal.seller.id;
          if (doc.type === IDocumentTypes.Generated) {
            const { sellerSignedDate, buyerSignedDate, isSellerSignatureRequired, isBuyerSignatureRequired } = doc;
            const signedDate = isUserSeller ? sellerSignedDate : buyerSignedDate;
            const sellerDone = isSellerSignatureRequired ? sellerSignedDate : true;
            const buyerDone = isBuyerSignatureRequired ? buyerSignedDate : true;
            const requiredSignaturesDone = sellerDone && buyerDone;

            doc.toReview = !signedDate && !requiredSignaturesDone;
          }

          doc.deal = deal;
          doc.temporaryName = doc.name;
          doc.editable = false;

          const { name, filename } = doc;
          const fileType = Utils.getFileType(filename || name);
          doc.viewable = Utils.isDocumentViewable(filename);
          doc.fileType = fileType;

          return doc;
        });
      })
    );
  }

  getChatDocuments(chatId: string, hideLoaderInterceptor = false): Observable<IListingDocumentBasic[]> {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http
      .get<{ documents: IListingDocumentBasic[] }>(`${this.config.apiUrl}/docs/chat/${chatId}`, { headers })
      .pipe(
        map((response) => {
          return response.documents;
        })
      );
  }

  createDealUploadedDocuments({ dealId, documents }: CreateDealUploadedDocumentsRequest) {
    return this.http.post(`${this.config.apiUrl}/docs/deals/${dealId}/uploaded`, { documents });
  }

  modifyDealUploadedDocuments({ dealId, documentId, document }: ModifyDealUploadedDocumentsRequest) {
    return this.http.put(`${this.config.apiUrl}/docs/deals/${dealId}/uploaded/${documentId}`, { document });
  }

  deleteDealUploadedDocuments({ dealId, documentId }: DeleteDealUploadedDocumentsRequest) {
    return this.http.delete(`${this.config.apiUrl}/docs/deals/${dealId}/uploaded/${documentId}`);
  }

  download(key: string, name: string, filename: string = '') {
    const url = `${this.config.staticUrl}/${key}`;
    return this.fetchAndDownload(url, name || filename, key);
  }
  
  private fetchAndDownload(url: string, name: string, key?: string) {
    const headers = new HttpHeaders().set('X-No-Authorization', '1');
  
    return this.http.get(url, { headers, responseType: 'blob', }).pipe(
      tap((data) => {
        try {
          const blob = this.createBlob(data);
          const downloadName = key ? this.getDownloadName(name, key, data) : name;
          this.triggerDownload(blob, downloadName);
        } catch (error) {
          this.notifService.notification(
            'error',
            'Something went wrong while downloading your file. Please try again or contact support.'
          );
        }
      })
    );
  }

  print(key: string, iframe: HTMLIFrameElement, isMobile): Observable<string> {
    if (this.gonativeService.detectIfNativeApp()) {
      this.notifService.notification(
        'info',
        `For a seamless printing experience, please download the file first and use your device's print option.`
      );
      return of();
    }

    const url = `${this.config.staticUrl}/${key}`;
    let headers = new HttpHeaders();
    headers = headers.set('X-No-Authorization', '1');

    return this.http.get(url, { headers, responseType: 'blob' }).pipe(
      map((data) => {
        const blob = this.createBlob(data);
        const blobUrl = URL.createObjectURL(blob);

        // Open in a new window for mobile devices to get around intermittent iframe issue on mobile
        if (isMobile) {
          const newWindow = window.open(blobUrl, '_blank');
          newWindow.onload = () => {
            newWindow.print();
            newWindow.onafterprint = () => newWindow.close();
          };
        } else {
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.onload = () => {
            iframe.contentWindow.print();
          };
        }
        return blobUrl;
      })
    );
  }

  createBlob(data: Blob) {
    return new Blob([data], { type: data.type });
  }

  getDownloadName(name, key, data) {
    const fileExt = this.getFileExtension(name) || this.getFileExtension(key) || data.type.split('/')[1];
    return name.includes('.') ? name : `${name}.${fileExt}`;
  }

  getFileExtension(filename) {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop() : '';
  }

  triggerDownload(blob, downloadName) {
    const url = window.URL.createObjectURL(blob);
    const a = this.document.createElement('a');
    a.href = url;
    a.download = downloadName;
    this.document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    this.document.body.removeChild(a);
  }

  signDealDocument({ documentId, dealId, signature }: SignDocumentRequest): Observable<IDealDocument> {
    return this.http.post<IDealDocument>(`${this.config.apiUrl}/docs/deals/${dealId}/sign/${documentId}`, {
      signature,
    });
  }

  getDocumentsListing(listingId: string, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http.get(`${this.config.apiUrl}/seller-listings/${listingId}/documents`, { headers }).pipe(
      map(({ documents }: any) => {
        return documents.map((doc) => {
          const { name, filename } = doc;

          const fileType = Utils.getFileType(filename);
          doc.fileType = fileType;
          doc.viewable = Utils.isDocumentViewable(filename);
          doc.temporaryName = name || filename;
          doc.type = IDocumentTypes.Shared;
          return doc;
        });
      })
    );
  }

  createDocumentsListing({ listingId, documents }: CreateDocumentsListingRequest) {
    return this.http.post(`${this.config.apiUrl}/seller-listings/${listingId}/documents`, { documents });
  }

  modifyDocumentsListing({ listingId, documentId, document }) {
    return this.http.put(`${this.config.apiUrl}/seller-listings/${listingId}/documents/${documentId}`, {
      document,
    });
  }

  deleteDocumentsListing({ listingId, documentId }) {
    return this.http.delete(`${this.config.apiUrl}/seller-listings/${listingId}/documents/${documentId}`);
  }
}
