<div>
  <div class="subtitle mb-2 text-start">Wait, are you sure?</div>
  <div class="description text-start" style="margin-bottom: 40px">
    Mobile phone verification is required to communicate with other users in the platform.
  </div>
  <div class="w-full d-flex flex-column align-items-center" style="gap: 12px">
    <button class="btn btn-primary w-100" (click)="onVerify.emit()">Get Verified</button>
    <a (click)="onSkipToManualEntry.emit()" class="opted-out">Continue without a mobile number</a>
  </div>
</div>
