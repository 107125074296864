import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { PlatoformsGeneratedDocument, PlatoformsGeneratedDocumentRoles } from 'src/app/deals/document.models';

interface SignCompletedDocumentResponse {
  success: boolean;
  data: PlatoformsGeneratedDocument;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class PlatoformsService {
  private baseUrl: string;

  constructor (
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.baseUrl = `${this.config.apiUrl}/platoforms`;
  }
  /**
   * Platoforms CORS is limited to only embedding in an iframe.
   * Alternative to downloading the PDF in a new tab.
   * @param url 
   * @param filename 
   */
  downloadPdf(url, filename: string = 'download.pdf') {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.target = '_blank';
    document.body.appendChild(anchor);  

    anchor.click();
  
    // Cleanup
    document.body.removeChild(anchor);
  }

  signCompletedDocument(dealId: string, documentId: string, role: PlatoformsGeneratedDocumentRoles, signature: string) {
    return this.http.post<SignCompletedDocumentResponse>(`${this.baseUrl}/sign`, { dealId, documentId, role, signature });
  }
}
