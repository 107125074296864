<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Status on Loan Payoff</div>

  <div class="lien-holder-info">
    <div class="py-3">
      <div class="info-text">Lien Holder Information: Wells Fargo</div>
      <div>
        <span class="fw-bold bold-price">
          ${{loanPayoffInformation?.loanBalance | mask: 'separator': { thousandSeparator: ','} }}
        </span>
        as of {{loanPayoffInformation?.loanBalanceLastChecked | date:'MM/dd/yyyy'}}
      </div>
    </div>

    <div class="status-container px-3 py-4">
      <div class="pb-2 fw-bold">Current status</div>
      <div class="step" *ngFor="let loan of loanPayoffStatusInfo; let i = index;">
        <div class="d-flex">
          <div class="position-relative d-flex flex-column align-items-center progress-section">
            <div
              class="circle circle-gray"
              [ngClass]="{
                       'circle-blue': loan.complete || i === currentStepIndex,
                     }"
            ></div>
            <div
              *ngIf="i < loanPayoffStatusInfo.length - 1"
              class="line line-gray"
              [ngClass]="{
                       'line-blue': loan.complete || i === currentStepIndex,
                     }"
            ></div>
          </div>
          <div class="ml-3" [ngClass]="{ 'pending-box w-100 p-2': !loan.complete && i === currentStepIndex }">
            <div class="step-text font-weight-bold">
              {{ loan.complete || i < currentStepIndex ? loan.finishedStepText : loan.pendingStepText }}
            </div>
            <div class="sub-text text-muted">
              {{ loan.complete ? loan.finishedSubText : i === currentStepIndex ? loan.pendingSubText : '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
